<template>
  <div>


      Dashboard page!

    You are logged in!
  </div>
</template>
<script>
export default {

}
</script>